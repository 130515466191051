










































import {Component, Prop, Watch} from 'vue-property-decorator';
import SearchView from '@/components/SearchView.vue';
import Notifications from '@/components/mixins/Notifications';
import {Getter} from 'vuex-class';
import CustomerContactListItem from '@/components/business/customers/CustomerContactListItem.vue';
import DialogCreateEditCustomer from '@/components/business/customers/DialogCreateEditCustomer.vue';
import {applicationStore} from '@/store/modules/application';
import {customersStore} from '@/store/modules/customers/CustomersStore';
import {profileStore} from '@/store/modules/profile';
import {businessStore} from '@/store/modules/business/businessStore';
import {mixins} from "vue-class-component";
import TextFieldEmail from "@/components/profile/privacy-security/TextFieldEmail.vue";
import EmptyState from "@/components/EmptyState.vue";

@Component({
  name: 'customers-list',
  components: {TextFieldEmail, CustomerContactListItem, SearchView, EmptyState, DialogCreateEditCustomer}
})
export default class CustomersList extends mixins(Notifications) {
  @Prop({default: 240}) width?: number | string;
  @Prop({default: 240}) maxWidth?: number | string;
  @Prop() customers!: any[];
  @Prop() type!: string;

  @Getter selectedCustomer;
  srcCustomers?: any[] = [];
  emptyIcon: string = ''
  emptyTitle: string = ''
  emptyDescription: string = ''

  public showCreateCustomerDialog: boolean = false;

  get isAdmin() {
    return applicationStore.isAdmin;
  }

  get showAdd() {
    return this.isAdmin && this.type === 'opt-in';
  }

  get selectedUser() {
    return businessStore.selectedUser
  }
  get photoUrl(): string {
    return this.selectedUser?.photoUrl?.normal || '';
  }
  get abbreviation() {
    return '';
  }

  get style() {
    return {
      width: `${this.width}px`,
      maxWidth: `${this.maxWidth}px`
    };
  }

  get isEmpty() {
    return !this.srcCustomers?.length || this.srcCustomers?.length === 0
  }

  @Watch('customers')
  onCustomersChanged(after, before) {
    if (!before) {
      return;
    }
    this.srcCustomers = Object.assign([], after);
    if (!!this.selectedCustomer) {
      this.onCustomerSelected(this.selectedCustomer)
    }
  }

  inPersonal(customer: any): boolean {
    return customer.personal?.includes(profileStore.t2bUser!.id);
  }

  onCustomerSelected(customer) {
    const routeName = this.type === 'blocked' ? 'blocked-customer-profile' : 'customer-profile'
    this.$router.push({name: routeName, params: {customerId: customer.id, type: this.type}});
  }

  async onAddToPersonalContacts(customerId) {
    await customersStore.addToPersonalContacts(customerId)
    this.showInfo('Added to My contacts')

  }

  search(term) {
    if (!term) {
      this.srcCustomers = Object.assign([], this.customers);
      return;
    }
    const searchString = term.toLowerCase();
    const fFunc = (value) => value.fullName.toLowerCase().includes(searchString);
    this.srcCustomers = this.customers!!.filter(fFunc);
  }

  initEmpty() {
    const name = this.$route.name
    const subtype = this.$route.query.subtype;
    console.log('initEmpty', name)
    switch (name) {
      case 'customers':
        this.emptyIcon = require('../../../assets/_empty_states_contacts.svg')
        this.emptyTitle = 'No customer contacts yet'
        this.emptyDescription = 'New customers with whom<br>' +
            'you can initiate communication<br>' +
            'with will appear here'
        break
    }
  }

  onChangesProfile() {
    //this.showInfo('Customer contact has been added');
  }

  updated() {
    this.initEmpty()
  }

  created() {
    this.srcCustomers = Object.assign([], this.customers);
    if (!!this.selectedCustomer) {
      this.srcCustomers.filter((cust) => {
        if(cust.id == this.selectedCustomer.id){
          this.onCustomerSelected(this.selectedCustomer)
        }
      })
    }
    this.initEmpty()
  }

  onExport(){
    this.exportCSV().then((csvData) => { 
      this.downloadCSV(csvData,'CustomerContacts.csv')
    })
  }

  async exportCSV(){
    let data = this.srcCustomers || []
    const exportedData = await [
    [
      "Full Name",
      "Email",
      "DOB",
      "Phone Number",  
    ],
    ...data.map(item => [
      item.fullName ?? '',
      item.email ?? '',
      item.dob ?? '',
      item.phoneNumber ?? '',
    ])
    ]
   .map(e => e.join(",")) 
   .join("\n");
   return exportedData
  }

  downloadCSV(content, filename) {
    var pom = document.createElement('a');
    var csvContent = content;
    var blob = new Blob([csvContent],{type: 'text/csv;charset=utf-8;'});
    var url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
  }

};
