import {inject, injectable, singleton} from 'tsyringe';
import {ApiService} from '@/domain/definitions/api';

@injectable()
@singleton()
export class RevokeInviteUseCase {
    // getBusinessUseCase: GetBusinessUseCase
    private apiService: ApiService

    constructor(@inject('ApiService') apiService: ApiService) {
        this.apiService = apiService
    }

    public invoke = async (businessId: string, inviteId: string) => {
        // businessId = getBusinessUseCase.invoke()
        await this.apiService.revokeInvite(businessId, inviteId);
    }
}
