































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import ToolTip from '@/components/custom/ToolTip.vue';

@Component({
  name: 'customer-contact-list-item',
  components: {AvatarWithStatus, ToolTip}
})
export default class CustomerContactListItem extends Vue {
  @Prop() item?: any;
  @Prop({default: false}) inPersonal?: boolean;
  @Prop({default: true}) border?: boolean;
  @Prop({default: false}) rounded?: boolean;
  @Prop({default: true}) listItem?: boolean;
  @Prop({default: 44}) avatarSize?: string | number;

  @Getter selectedCustomer;

  isOnload: boolean = true;
  isHover: boolean = false;
  subTitleCropped: boolean = false;  

  get sizes() {
    return {
      'x-small': 44,
      'small': 48,
      'medium': 56
    };
  }

  get selected(): boolean {
    let href = window.location.href;
    if(this.isOnload && href.includes('contacts/shared')){      
      return false;
    }    
    return this.selectedCustomer?.id === this.item?.id;    
  }

  get title(): string {
    return this.item?.fullName;
  }

  get subtitle(): string {
    return `${this.item?.email}`;
  }

  get photoUrl(): string {
    return this.item?.photoUrl?.normal;
  }

  get online(): boolean {
    return !!this.item?.status?.online;
  }

  get optedOut() {
    return !this.item?.permissions?.contact
  }

  onItemSelected() {
    this.isOnload = false;
    //if (!this.selected) 
    {
      this.$emit('contact-clicked', this.item);
    }
  }

  onIconHoverClicked(itemId) {
    this.$emit('add-to-personal-clicked', itemId);
    this.onItemSelected();
  }

  subTitleWrap() {    
    try{
      this.$nextTick(() => {        
          let wrap = this.$refs.wrap        
          this.subTitleCropped = wrap['offsetWidth'] < wrap['scrollWidth'];        
      });    
    } catch (e) {    
      console.log(e);
    }
    return true   
  }

};
