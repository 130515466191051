import {AssociatesRepository} from '@/data/associatesDataRepository';
import {Associate} from '@/domain/model/associate';
import {DataSet} from '@/data/repository';
import {inject, injectable, singleton} from 'tsyringe';

@injectable()
@singleton()
export class GetAssociatesUseCase {
    private associatesRepository: AssociatesRepository;

    constructor(@inject('AssociatesRepository') associatesRepository: AssociatesRepository) {
        this.associatesRepository = associatesRepository;
    }

    public invoke(businessId: string): Promise<Associate[]> {
        return this.associatesRepository.findAll(businessId);
    };

    public invokeAsync(businessId: string, callback: DataSet<Associate>): () => void {
        return this.associatesRepository.findAllAsync(businessId, callback);
    }

    public clear() {
        this.associatesRepository.clear();
    }
}
