
















import {Component} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';
import CustomersList from '@/components/business/customers/CustomersList.vue';
import {Getter} from 'vuex-class';

@Component({
  name: 'blocked-users',
  components: {CustomersList}
})
export default class BlockedUsers extends mixins<any>(Notifications) {
  @Getter public blockedCustomers
}
