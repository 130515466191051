import {Associate} from '@/domain/model/associate';
import {PhotoRepository} from '@/data/photoDataRepository';
import {AssociatesRepository} from '@/data/associatesDataRepository';
import {inject, injectable, singleton} from 'tsyringe';

@injectable()
@singleton()
export default class AddAssociateUseCase {
  private associatesRepository: AssociatesRepository;
  private photoRepository: PhotoRepository;

  constructor(@inject('AssociatesRepository') associateRepository: AssociatesRepository,
              @inject('PhotoRepository') photoRepository: PhotoRepository) {
    this.associatesRepository = associateRepository;
    this.photoRepository = photoRepository;
  }

  public invoke = async (associate: Associate, profilePhoto?: File): Promise<Associate | null> => {
    if (await this.associatesRepository.exists(associate)) {
      return null;
    }
    const user = await this.associatesRepository.save(associate);
    if (!!profilePhoto) {
      await this.photoRepository.save({associateId: user.id, file: profilePhoto});
    }
    return user;
  };
}
