












import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'empty-state'
})
export default class EmptyState extends Vue {
  @Prop() value!: boolean;
  @Prop({type: String}) icon
  @Prop({type: String}) title
  @Prop({type: String}) description
  @Prop({type: String}) extraClass
  @Prop({type: Boolean, default: false}) arrow
  @Prop({type: Number, default: 264}) paddingTop

  get emptyClass() {
    let classList = ['empty-list']
    if (this.arrow) {
      classList.push('point-arrow')
    }
    if (this.extraClass) {
      classList.push(this.extraClass)
    }
    return classList.join(' ')
  }

  get style() {
    return { paddingTop: `${this.paddingTop}px`}
  }
}
