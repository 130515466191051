import {Component, Vue} from 'vue-property-decorator';
import {notificationsStore} from '@/store/modules/notifications';
import {NotificationsPositionVertical, NotificationsSystem, NotificationsType, UndoSystem} from '@/domain/model/types';

@Component({
  name: 'notifications'
})
export default class Notifications extends Vue {

  protected get notificationsSystem() {
    return notificationsStore.notificationsSystem;
  }

  // todo: position should be horizontal and vertical
  private static showNotification(type, text, icon, timeout, positionHoriz) {
    const notification: NotificationsSystem = {
      type,
      text,
      icon,
      timeout,
      position: {
        horizontal: positionHoriz,
        vertical: NotificationsPositionVertical.TOP
      }
    };
    notificationsStore.showNotification(notification);
  }

  protected showInfo(text: string, icon: string | null = null, timeout: number | null = null, positionHoriz = 'right') {
    Notifications.showNotification(NotificationsType.INFO, text, icon, timeout, positionHoriz);
  }

  protected showWarning(text: string, icon: string | null = null, timeout: number | null = null, positionHoriz = 'right') {
    Notifications.showNotification(NotificationsType.WARNING, text, icon, timeout, positionHoriz);
  }

  protected showIssue(text: string, icon: string | null = null, timeout: number | null = null, positionHoriz = 'right') {
    Notifications.showNotification(NotificationsType.ISSUE, text, icon, timeout, positionHoriz);
  }

  protected showSuccess(text: string, icon: string | null = null, timeout: number | null = null, positionHoriz = 'right') {
    Notifications.showNotification(NotificationsType.SUCCESS, text, icon, timeout, positionHoriz);
  }

  protected showMailed(text: string, icon: string | null = null, timeout: number | null = null, positionHoriz = 'right') {
    Notifications.showNotification(NotificationsType.MAILED, text, icon, timeout, positionHoriz);
  }

  protected async notifyNewMessage(type: string = 'request') {
    await notificationsStore.notify(type)
  }

  protected showUndo(id: string, type: string, text: string, data: any, callback: any, timeout: number = 5) {
    const undo: UndoSystem = {
      id, type, text, data, callback, timeout
    }
    notificationsStore.showUndo(undo);
  }
}
