import store from '@/store';
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import AddAssociateUseCase from '@/domain/addAssociateUseCase';
import EditUserUseCase from '@/domain/editUserUseCase';
import {SendInviteUseCase} from '@/domain/sendInviteUseCase';
import {Associate} from '@/domain/model/associate';
import {GetAssociatesUseCase} from '@/domain/getAssociatesUseCase';
import {DataSet} from '@/data/repository';
import {Roles} from '@/domain/model/types';
import {Invite} from '@/domain/model/invite';
import axios from '@/plugins/axios';
import {firestore} from '@/plugins/firebase.init';
import {applicationStore} from '@/store/modules/application';
import {container} from 'tsyringe';
import {ClearInviteUseCase} from '@/domain/clearInviteUseCase';
import {UpdateFirebaseUserPasswordUseCase} from '@/domain/updateFirebaseUserPasswordUseCase';
import {AddFirebaseUserUseCase} from '@/domain/addFirebaseUserUseCase';
import {CreateCustomContactUseCase} from '@/domain/createCustomContactUseCase';
import {profileStore} from '@/store/modules/profile';

import {Business} from '@/domain/model/business';

import {
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
  writeBatch
} from 'firebase/firestore';
import {RevokeInviteUseCase} from '@/domain/revokeInviteUseCase';
import {ReInviteUserUseCase} from '@/domain/reInviteUserUseCase';
import {business, businessAssociate, businessInvites, user} from '@/data/firebase';

const addFirebaseUserUseCase = container.resolve<AddFirebaseUserUseCase>('AddFirebaseUser')
const getAssociatesUseCase = container.resolve(GetAssociatesUseCase);
const addAssociateUseCase = container.resolve(AddAssociateUseCase);
const editUserUseCase = container.resolve(EditUserUseCase);
const sendInviteUseCase = container.resolve(SendInviteUseCase)
const clearInviteUseCase = container.resolve(ClearInviteUseCase)
const updateFirebaseUserPassword = container.resolve(UpdateFirebaseUserPasswordUseCase)
const createContact = container.resolve(CreateCustomContactUseCase)
const revokeInvite = container.resolve(RevokeInviteUseCase)
const reInviteUser = container.resolve<ReInviteUserUseCase>('ReInviteUser')

@Module({dynamic: true, store, name: 'business-store'})
export default class BusinessStore extends VuexModule {

  private static isExpired(date?: Date) {
    if (date) {
      const now = new Date();
      return ((now.getTime() - date.getTime()) / 1000 / 60) >= 360
    }
    return true
  }

  private _saving: boolean = false;
  private _error: string = '';
  private _message: string = '';
  private _associates: Associate[] = [];
  private _invited: any[] = [];
  private _selectedUser: any = null
  private _inviteEmailExist: string[] = []

  get selectedUser() {
    return this._selectedUser
  }

  get invited() {
    return this._invited;
  }

  get associates() {
    return this._associates;
  }

  get associatesActivated() {
    return this._associates.filter((item) => !item.invited)
  }

  get associatesInvited() {
    return this._associates
      .filter((item) => item.invited)
      .map((item: any) => ({...item, expired: BusinessStore.isExpired(item.invitedAt?.toDate())}));
  }

  get error(): string {
    return this._error;
  }

  get message(): string {
    return this._message;
  }

  get saving(): boolean {
    return this._saving;
  }

  get inviteEmailExist() {
    return this._inviteEmailExist
  }

  @Action
  public async loadInvited() {
    const businessId = applicationStore.businessId
    if (!businessId) {
      return
    }
    const invites = await getDocs(query(businessInvites(businessId),
      where('mode', '==', 'signUp'),
      orderBy('createDate', 'desc')));
    this.setInvited(invites.docs.map((doc) => {
      return {id: doc.id, ...doc.data(), expired: BusinessStore.isExpired(doc.data().createDate?.toDate())}
    }));
  }

  @Action
  public loadInvitedAsync(): () => void {
    const businessId = applicationStore.businessId
    if (!businessId) {
      return () => {
      }
    }
    const {setInvited, setError} = this
    const invitesQuery = query(businessInvites(businessId),
      where('mode', '==', 'signUp'),
      orderBy('createDate', 'desc'))
    return onSnapshot(invitesQuery, (snapshot) => {
      setInvited(snapshot.docs.map((doc) => {
        return {
          id: doc.id, ...doc.data(),
          expired: BusinessStore.isExpired(doc.data().createDate?.toDate())
        }
      }));
    }, (error) => {
      setError(error.message)
      console.error(error)
    });
  }

  @Action
  public async loadAssociates() {
    const associates = await getAssociatesUseCase.invoke(applicationStore.businessId!);
    this.setAssociates(associates);
  }

  @Action
  public loadAssociatesAsync(): () => void {
    const {setAssociates, setError} = this;
    return getAssociatesUseCase.invokeAsync(applicationStore.businessId!, {
      onChanged(dataSet: Associate[]) {
        setAssociates(dataSet);
      },
      onError(message: string) {
        setError(message);
      }
    } as DataSet<Associate>);
  }

  //todo: check usage!
  @Action
  public async addUser2(user: UserData): Promise<boolean> {
    this.setSaving(true);
    try {
      await axios.post(`/businesses/${this.context.rootGetters.business.id}/invites`, {
        fullName: user.name,
        password: user.password,
        position: user.position,
        phone: user.phoneNumber,
        photo: user.profilePhoto,
        email: user.email,
        role: user.role,
        mode: user.mode,
        sendInvite: user.sendInvite,
        createContact: user.autoCreateContact
      }, {
        headers: {
          ['Content-Type']: 'application/json'
        },
        validateStatus: (status) => status === 200
      });
      this.setMessage(`${user.name} has been added`);
      this.setSaving(false);
      return true;
    } catch (e: any) {
      this.setError(e.message);
      this.setSaving(false);
      return false;
    }
  }

  @Action
  public async createAssociateProfile(data: UserData): Promise<boolean> {
    this.setSaving(true);
    const roles: Roles = {
      associate: data.role >= 1,
      admin: data.role === 3,
      superAdmin: false
    }
    try {
      
      const newAssociate: Associate = new Associate(data.name, data.email, data.position, data.phoneNumber,
        {
          id: data.business!.id,
          name: data.business!.name
        }, 1, roles.admin, roles.superAdmin, roles);
      newAssociate.id = data.id;

      const associate = await addAssociateUseCase.invoke(newAssociate, data.profilePhoto);
    
      if (!associate) {
        this.setError(`associate with ${data.email} exists!`);
        this.setSaving(false);
        return false;
      }else{       
        let businessData : Business = new Business(data.business!.name)
        businessData.id = data.business!.id
        
        const contact = await createContact.invoke(businessData, associate);
        if (!!contact) {
          await writeBatch(firestore)
            .update(user(associate.id!), 'defaultContactId', contact.id)
            .update(businessAssociate(businessData.id!, associate.id!), 'defaultContactId', contact.id)
            .commit();
        }
      }

      // await useCases.updateFirebaseUserProfile.invoke(user.name); //todo: eliminate, handled on CloudFunctions side
      await updateFirebaseUserPassword.invoke(data.password!);
    } catch (e: any) {
      this.setError(e.message);
      this.setSaving(false);
      return false;
    }
    this.setMessage(`${data.name} has been added`);
    this.setSaving(false);
    return true;
  }

  @Action
  public async addUser(data: UserData): Promise<boolean> {
    this.setSaving(true);
    try {
      const uid = await addFirebaseUserUseCase(data.email, data.name);
      const rootGetters = this.context.rootGetters;
      const businessData = rootGetters.business;

      const roles: Roles = {
        associate: data.role >= 1,
        admin: data.role === 3,
        superAdmin: false
      }

      const newAssociate: Associate = new Associate(data.name, data.email, data.position, data.phoneNumber,
        {id: businessData.id, name: businessData.name}, 1, roles.admin, roles.superAdmin, roles);
      newAssociate.id = uid;
      if (data.sendInvite) {
        newAssociate.invited = true
        newAssociate.invitedAt = new Date()
      }

      const associate = await addAssociateUseCase.invoke(newAssociate, data.profilePhoto);

      if (!associate) {
        this.setError(`Associate with ${data.email} exists!`);
        this.setSaving(false);
        return false;
      }

      if (data.autoCreateContact) {
        const contact = await createContact.invoke(businessData, associate);
        if (!!contact) {
          await writeBatch(firestore)
            .update(user(associate.id!), 'defaultContactId', contact.id)
            .update(businessAssociate(businessData.id, associate.id!), 'defaultContactId', contact.id)
            .commit();
        }
      }

      if (data.sendInvite) {
        await sendInviteUseCase.invoke(businessData, new Invite(0, uid!, data.email, data.mode, data.role));
      }
    } catch (e: any) {
      this.setError(e.message);
      this.setSaving(false);
      return false;
    }
    this.setMessage(`${data.name} has been added`);
    this.setSaving(false);
    return true;
  }

  @Action
  public async editUser(data: { user: any, profilePhoto: File | null }): Promise<boolean> {
    const associate = data.user;
    if (!associate) {
      return false;
    }
    try {
      this.setSaving(true);
      const roles: Roles = {
        associate: associate.role >= 1,
        admin: associate.role === 3,
        superAdmin: false
      }
      const editAssociate: Associate = new Associate(
        associate.name, associate.email, associate.position, associate.phoneNumber,
        {id: associate.business!.id, name: associate.business!.name},
        1, roles.admin, roles.superAdmin, roles);
      editAssociate.id = associate.id;
      await editUserUseCase.invoke(editAssociate, data.profilePhoto);
      this.setSaving(false)
      return true
    } catch (err: any) {
      this.setError(err.message)
      console.error(err)
    }
    this.setSaving(false)
    return true;
  }

  @Action
  public async clearInvite({businessId, email}): Promise<boolean> {
    try {
      await clearInviteUseCase.invoke(businessId, email);
      return true
    } catch (e: any) {
      this.setError(e.message);
    }
    return false
  }

  @Action
  public async reinviteExistingUser(data: { item: any, mode: string }): Promise<boolean> {
    const invite = new Invite(0, data.item.id, data.item.email, data.mode);
    if (data.item.roles) {
      invite.role = data.item.roles.admin ? 3 : 1
    }
    const invited = await this.inviteUser(invite);
    if (invited) {
      await reInviteUser(data.item.id)
    }
    return invited
  }

  @Action
  public async reinviteUser(data: { item: any, mode: string }): Promise<boolean> {
    const invite = new Invite(0, data.item.id, data.item.email, data.mode, data.item.role);
    return this.inviteUser(invite)
  }

  @Action
  public async inviteUser(invite: Invite): Promise<boolean> {
    const businessData = applicationStore.business
    try {
      await sendInviteUseCase.invoke({id: businessData!.id, name: businessData!.name}, invite)
      this.setMessage('Invite has been sent');
      return true;
    } catch (e: any) {
      this.setError(e.message);
      return false;
    }
  }

  @Action
  public async inviteUsers(invites: Invite[]): Promise<boolean> {
    this.setSaving(true);
    const businessData = applicationStore.business
    let email: string = ''
    let result = false
    try {
      for (const invite of invites) {
        email = invite.email
        const uid = await addFirebaseUserUseCase(invite.email);
        invite.id = uid!;
        result = await sendInviteUseCase.invoke({id: businessData!.id, name: businessData!.name}, invite);
        console.log('sendInviteUseCase.invoke: ' + result)
      }
      this.setMessage('Invites have been sent');
      this.setSaving(false);
      return result;
    } catch (e: any) {
      if (e.code === 'auth/email-already-in-use') {
        this.addEmailExist(email)
      }
      this.setError(e.message);
      this.setSaving(false);
      return false;
    }
  }

  @Action
  public async revokeUserInvite(inviteId: string) {
    const businessId = applicationStore.business?.id //todo: extract GetBusinessUseCase
    if (!businessId) {
      return false
    }
    try {
      await revokeInvite.invoke(businessId, inviteId)
      return true
    } catch (err) {
      console.error(err, 'revokeUserInvite')
    }
    return false
  }

  @Action
  public clear() {
    this.context.rootGetters.getUsers.clear();
  }

  @Mutation
  private setInvited(invites: any[]) {
    this._invited = invites;
  }

  @Mutation
  private setAssociates(associates: Associate[]) {
    this._associates = associates;
  }

  @Mutation
  public setSaving(value: boolean) {
    this._saving = value;
  }

  @Mutation
  public setError(error: string) {
    this._error = error;
  }

  @Mutation
  private setMessage(message: string) {
    this._message = message;
  }

  @Mutation
  private selectUser(selectedUser: any) {
    this._selectedUser = selectedUser
  }

  @Mutation
  private addEmailExist(email: string) {
    this._inviteEmailExist.push(email)
  }

  @Action
  public async saveCompanyInfo(payload: any) {
    const businessId = applicationStore.businessId;
    const userId = profileStore.t2bUser?.id;
    if (!businessId || !userId) {
      console.log('invalid user or business');
      return false;
    }
    try {
      this.setSaving(true)
      await setDoc(business(businessId), {
        ...payload.payload,
        createdDate: Timestamp.now()
      }, {merge: true})
      this.setSaving(false)
      return true;
    } catch (e: any) {
      this.setError(e.message);
    }
    return false
  }

  @Action
  public async updateCompanyInfo(payload: any) {
    const businessId = applicationStore.businessId;
    const userId = profileStore.t2bUser?.id;
    if (!businessId || !userId) {
      console.log('invalid user or business');
      return false;
    }
    console.log('updateCompanyInfo => ', businessId)
    try {
      this.setSaving(true)
      await updateDoc(business(businessId), payload)
      this.setSaving(false)
      console.log('updateCompanyInfo => updated')
      return true;
    } catch (e: any) {
      this.setError(e.message);
      console.error('updateCompanyInfo => ', e)
    }
    return false
  }

  @Action({commit: 'selectUser'})
  public async loadUser(userId: string) {
    let found = this._associates.find((item) => item.id === userId);
    if (!found) {
      const userDoc = await getDoc(user(userId))
      if (userDoc.exists()) {
        found = {id: userDoc.id, ...userDoc.data()} as Associate
      }
    }
    return found;
  }
}

export interface UserData {
  id?: string
  name: string
  password?: string
  email: string
  position: string
  phoneNumber: string
  role: number
  autoCreateContact?: boolean
  profilePhoto?: any
  sendInvite: boolean
  mode?: string
  ssn?: string
  business?: { id: string, name: string }
}

export const businessStore = getModule(BusinessStore);
