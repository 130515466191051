import {Geopoint} from '@/domain/model/types';
import {BaseClass} from '@/domain/model/base_object';

export class Business extends BaseClass {
    public id?: string;
    public name: string;
    public address?: string;
    public phone?: string;
    public email?: string;
    public website?: string;
    public description?: string;
    public logoUrl?: string;
    public geopoint?: Geopoint;
    public category?: string;
    public tags?: string[];

    constructor(name: string, id?: string, address?: string, phone?: string, email?: string, website?: string,
                description?: string, logoUrl?: string, geopoint?: Geopoint, category?: string, tags?: string[]) {
        super();
        this.id = id;
        this.name = name;
        this.address = address;
        this.phone = phone;
        this.email = email;
        this.website = website;
        this.description = description;
        this.logoUrl = logoUrl;
        this.geopoint = geopoint;
        this.category = category;
        this.tags = tags;
    }
}
