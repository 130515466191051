import { render, staticRenderFns } from "./CustomersList.vue?vue&type=template&id=07d663df&scoped=true&"
import script from "./CustomersList.vue?vue&type=script&lang=ts&"
export * from "./CustomersList.vue?vue&type=script&lang=ts&"
import style0 from "./CustomersList.vue?vue&type=style&index=0&id=07d663df&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07d663df",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VImg,VList,VListItemTitle,VToolbar})
