




























































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'base-dialog'
})
export default class BaseDialog extends Vue {
  @Prop() value!: boolean;
  @Prop({default: false}) back!: boolean;
  @Prop({default: true}) close!: boolean;
  @Prop({default: ''}) title?: string;
  @Prop({default: ''}) subtitle?: string;
  @Prop({default: 576}) width?: number;
  @Prop({default: 576}) maxWidth?: number;
  @Prop({default: 769}) height?: number;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  onClose() {
    this.$emit('close-dialog');
    this.show = false;
  }
}
