import {InvitesRepository} from '@/data/invitesDataRepository';
import {inject, injectable, singleton} from 'tsyringe';

@injectable()
@singleton()
export class ClearInviteUseCase {
    private invitesRepository: InvitesRepository;

    constructor(@inject('InvitesRepository') invitesRepository: InvitesRepository) {
        this.invitesRepository = invitesRepository;
    }

    invoke = async (businessId: string, email: string) => {
        const invites = await this.invitesRepository.findAll(businessId, {
            field: 'email',
            operator: '==',
            value: email
        });
        for (const invite of invites) {
            await this.invitesRepository.delete(businessId, invite);
        }
    };
}
