import {InvitesRepository} from '@/data/invitesDataRepository';
import {Invite} from '@/domain/model/invite';
import {inject, injectable, singleton} from 'tsyringe';
import axios from 'axios';
import {Auth} from 'firebase/auth';

@injectable()
@singleton()
export class SendInviteUseCase {
  private auth: Auth
  private invitesRepository: InvitesRepository;

  constructor(@inject('FirebaseAuth') auth: Auth,
              @inject('InvitesRepository') invitesRepository: InvitesRepository) {
    this.auth = auth
    this.invitesRepository = invitesRepository;
  }

  invoke = async (business: { id, name }, invite: Invite): Promise<boolean> => {
    try {
      // todo: consider move this call to the remote API /usersService.invite
      const savedInvite = await this.invitesRepository.save(business, invite);
      const currentUser = this.auth.currentUser;
      const token = currentUser ? await currentUser.getIdToken(false) : '';
      const result = await axios.post('/authService.inviteUser', { //todo: should be /usersService.invite
        email: savedInvite.email,
        mode: savedInvite.mode,
        role: savedInvite.role,
        bid: business.id,
        bn: business.name,
        uid: savedInvite.id
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('response:', result.data)
      return true
    } catch (err) {
      console.error(err)
    }
    return false
  };
}
