import {auth} from '@/plugins/firebase.init';
import {injectable, singleton} from 'tsyringe';
import {updatePassword} from 'firebase/auth';

@injectable()
@singleton()
export class UpdateFirebaseUserPasswordUseCase {
  invoke = async (password: string): Promise<void> => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return;
    }
    await updatePassword(currentUser, password);
  };
}
