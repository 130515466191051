





















































































import {Component, Prop, Watch} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {Action, Getter} from 'vuex-class';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {AssociateAccount} from '@/domain/model/types';
import {storage} from '@/plugins/firebase.init';
import {twoChars} from '@/utils/helpers';
import {customersStore} from '@/store/modules/customers/CustomersStore';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';
import {ref, uploadBytes} from 'firebase/storage';

@Component({
  name: 'dialog-create-edit-customer',
  components: {AvatarWithStatus, BaseDialog}
})
export default class DialogCreateEditCustomer extends mixins(Notifications) {
  @Prop() value!: boolean;
  @Prop() user?: any;
  @Prop() edit!: boolean;

  srcCustomer?: AssociateAccount | null = null;

  id: string = '';
  customerName: string = '';
  customerEmail: string = '';

  customerImageFile: File | null = null;
  customerImageUrl: string | null = null;
  phoneNumber: string = '' 
  disable: boolean = true
  saving: boolean = false;  

  @Action savePrivateInfoChanges;
  @Action uploadProfilePhoto;  

  get isEdit() {
    return this.edit || false;
  } 

  get title() {
    return this.isEdit ? 'Edit customer contact' : 'Create customer contact';
  }

  get abbreviation() {
    if(this.isEdit || (this.customer?.fullName && this.customer!.fullName.length > 1)){
      return twoChars(this.customer!.fullName);
    }    
    return '';
  }

  get customer() {
    return this.user;
  }

  get disableButton() {
    if(this.isEdit)
      return this.saving || (!this.privateInfoChanged && !this.avatarPhotoChanged) || this.disable;
    else
      return this.saving ||  !this.privateInfoChanged && this.disable;    
  }

  get avatar() {   
      return this.customerImageUrl || (this.customer?.photoUrl && this.customer?.photoUrl?.thumbnail);   
  }

  get privateInfoChanged(): boolean {
    const phoneChanged = this.phoneNumber?.replace(/\D/g, '') !== this.srcCustomer?.phoneNumber?.replace(/\D/g, '')   
    return !!this.srcCustomer && !!this.srcCustomer.id && phoneChanged;
  }

  get avatarPhotoChanged() {
    return !!this.customerImageFile && !!this.customerImageUrl;
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  /*@Watch('customerName')
  onChangeName() {
    const formPrivateInfo: any = this.$refs.formPrivateInfo
    this.disable = !formPrivateInfo.validate()
  }*/

  @Watch('customerEmail')
  onChangeEmail() {
    const formPrivateInfo: any = this.$refs.formPrivateInfo
    this.disable = !formPrivateInfo.validate()
  }

  @Watch('phoneNumber')
  onChangePhone() {
    const formPrivateInfo: any = this.$refs.formPrivateInfo
    this.disable = !formPrivateInfo.validate()
  }
  
  
  async onAddChanges() {

    this.saving = true;

    try{

      //check isUserExist
      const isUserExist = await customersStore.isUserExist(this.customerEmail)      
      if(isUserExist[0] != undefined){        
        this.saving = false;
        this.showIssue('Email already exists.');
        return;
      }

      // user data
      let userData = {
        name: this.customerName,
        email: this.customerEmail,
        position: '',
        phoneNumber:  this.phoneNumber,
        role: 2,
        autoCreateContact: false,
        sendInvite: false,
        mode: 'signInOnly',
        isManual: true
      }

      // add user
      let ok = await customersStore.addUser({ customer: userData, userId: ''});
      console.log(ok)

      // if email exists error from firebase, update type as 2
      if (isUserExist[0] == undefined && ok[0] == true && ok[1] == false) {
        let userId =  await customersStore.getUserId(this.customerEmail);        
        if(userId){
            console.log(userId)                       
            ok = await customersStore.addUser({ customer: userData, userId: userId});  
        }
      }

      // error cas3
      if (ok[0] == true && ok[1] == false) {
          this.saving = false;          
          this.showIssue('Failed to create customer contact');
          return;
      }     

      // success
      this.show = false;
      this.showInfo(`${this.customerName} has been added`);      
    }
    catch (err) {        
        this.showIssue('Failed to create customer contact');
        console.log(`error: ${err}`);
    }

  }
 
  async onEditChanges() {

    this.saving = true;

    try {
     
      const formPrivateInfo: any = this.$refs.formPrivateInfo;
      if (this.privateInfoChanged && formPrivateInfo.validate()) {
        const {phoneNumber} = this       
        this.customer.phoneNumber = phoneNumber
        const ok = await customersStore.editUser(this.customer)
        if (!ok) {
          this.saving = false;
        }
      }

      // Upload profile photo
      if (this.avatarPhotoChanged) {       
        const uploadData = {
          customerId: this.customer!.id,
          imageFile: this.customerImageFile
        };      
        const imagesRef = ref(storage, `users/${uploadData.customerId}/profilePhoto/${uploadData.imageFile!.name}`);
        const result = await uploadBytes(imagesRef, uploadData.imageFile!);
        console.log(`Profile photo has been uploaded - ${result.ref}`);   
        
        const downloadURL = result.ref.toString();
        this.customer.profilePhoto = downloadURL;
        const ok = await customersStore.editUserPhotoUrl(this.customer);  
        if (!ok) {
          this.saving = false;
        } 
        window.location.reload()
      }

      this.show = false;
      this.$emit('on-changes-saved');
    } catch (err) {
      this.$emit('on-error');
      console.log(`error: ${err}`);
    }
    this.saving = false;
  }

  onCustomerImageFileSelected(event) {
    console.log('DIALOG_EDIT_PROFILE -> onCustomerImageFileSelected');
    this.customerImageFile = event.target.files[0];
    if (this.customerImageFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.customerImageUrl = e.target.result || null;
      };
      reader.readAsDataURL(this.customerImageFile);      
    }
  }

  onCloseDialog() {
    if (this.privateInfoChanged) {
      Object.assign(this.customer, this.srcCustomer)
    }
  }

  created() {
    if(this.isEdit){
      this.id = this.user.id;
      this.srcCustomer = Object.assign({}, this.customer)     
      this.customerName = this.customer?.fullName
      this.customerEmail = this.customer?.email 
      this.phoneNumber = this.customer?.phoneNumber     
    }
  }

  updated() {
    console.log('DIALOG_EDIT_PROFILE -> updated');
  }

  beforeDestroy() {
    console.log('DIALOG_EDIT_PROFILE -> beforeDestroy');    
  }
}
