
















import Component from 'vue-class-component';
import Vue from 'vue';
import {Prop} from 'vue-property-decorator';
import {twoChars} from '@/utils/helpers';

@Component({
  name: 'avatar-with-status',
  filters: {twoChars}
})
export default class AvatarWithStatus extends Vue {
  @Prop({default: 'fiber_manual_record'}) icon?: string;
  @Prop({type: [Number, String], default: 12}) statusSize!: number;
  @Prop({type: [Number, String], default: 48}) size;
  @Prop({default: ''}) bgColor!: string;
  @Prop() imageUrl?: string;
  @Prop() letters?: string;
  @Prop() name?: string;
  @Prop({default: false}) online!: boolean;
  @Prop({default: false}) stroked!: boolean;

  get textStyle() {
    return {
      fontSize: `${this.size / 3}px`
    }
  }

  get iconStyle() {
    return {
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: `${this.statusSize}px`,
      maxWidth: `${this.statusSize}px`,
      height: `${this.statusSize}px`,
      maxHeight: `${this.statusSize}px`,
      backgroundColor: '#88DE31',
      boxSizing: 'border-box',
      border: '1px solid #FFFFFF',
      borderRadius: `${this.statusSize! / 2}px`
    };
  }

  get radius() {
    return (this.size as number) / 2;
  }

  get style() {
    let styleList = {
      borderRadius: `${this.radius}px`
    }
    if (this.bgColor) {
      styleList['backgroundColor'] = `${this.bgColor} !important`
    }
    return styleList
  }
}
