import store from '@/store';
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {applicationStore} from '@/store/modules/application';
import {addDoc, arrayRemove, arrayUnion, onSnapshot, orderBy, query, setDoc, 
  getDoc, getDocs, updateDoc, where} from 'firebase/firestore';
import {profileStore} from '@/store/modules/profile';
import {AssociateAccount, UserAccount, Roles} from '@/domain/model/types';
import {businessCustomer, businessCustomers, businessCustomerNotes, userReports} from '@/data/firebase';
import {auth} from '@/plugins/firebase.init'
import {fetchSignInMethodsForEmail } from 'firebase/auth';
import {businessStore} from '@/store/modules/business/businessStore';
import {user} from '@/data/firebase';
import {Associate} from '@/domain/model/associate';

import {container} from 'tsyringe';
import {AddFirebaseUserUseCase} from '@/domain/addFirebaseUserUseCase';
const addFirebaseUserUseCase = container.resolve<AddFirebaseUserUseCase>('AddFirebaseUser')

import AddAssociateUseCase from '@/domain/addAssociateUseCase';
const addAssociateUseCase = container.resolve(AddAssociateUseCase);

const env: any = process.env;

@Module({name: 'customers-store', dynamic: true, store})
export default class CustomersStore extends VuexModule {
  private _notes: any[] = []
  private _notesLoaded: boolean = false

  associates: { data: any, selected: boolean }[] = [];

  get notes() {
    return this._notes
  }

  get notesLoaded() {
    return this._notesLoaded
  }

  @Mutation
  public setNotesLoaded(loaded: boolean) {
    this._notesLoaded = loaded
  }

  @Mutation
  public setNotes(notes: []) {
    this._notes = notes
  }

  @Mutation
  public addNote(note: any) {
    this._notes.push(note)
  }

  @Mutation
  public updateNote(note: any) {
    this._notes.splice(this._notes.findIndex((item) => item.id === note.id), 1, note);
  }

  @Mutation
  public removeNote(note: any) {
    this._notes.splice(this._notes.findIndex((item) => item.id === note.id), 1);
  }

  @Action
  public async toggleVipStatus(customer) {
    if (!customer) {
      return;
    }
    try {
      const businessId = applicationStore.businessId;
      await updateDoc(businessCustomer(businessId!, customer.id), 'vip', !customer.vip);
    } catch (err) {
      console.error(err);
    }
  }

  @Action
  public async toggleSharedStatus(customer) {
    if (!customer) {
      return;
    }
    try {
      const businessId = applicationStore.businessId;
      const shared = !customer.shared;
      await updateDoc(businessCustomer(businessId!, customer.id), 'shared', shared);
      if (!shared) {
        this.context.commit('setSelectedCustomer', null)
      }
    } catch (err) {
      console.error(err);
    }
  }

  @Action
  public async addToPersonalContacts(customerId: string) {
    try {
      const userId = profileStore.t2bUser!.id;
      const businessId = applicationStore.businessId;
      await updateDoc(businessCustomer(businessId!, customerId), 'personal', arrayUnion(userId));
    } catch (err) {
      console.error(err);
    }
  }

  @Action
  public async removeFromPersonalContacts(customerId: string) {
    try {
      const userId = profileStore.t2bUser!.id;
      const businessId = applicationStore.businessId;
      await updateDoc(businessCustomer(businessId!, customerId), 'personal', arrayRemove(userId));
      this.context.commit('setSelectedCustomer', null)
    } catch (err) {
      console.error(err);
    }
  }

  @Action
  public async loadNotes(customerId: string) {
    const businessId = applicationStore.businessId;
    if (!businessId) {
      return () => null
    }
    this.setNotesLoaded(false)
    this.setNotes([])
    const notesQuery = query(
      businessCustomerNotes(businessId, customerId),
      orderBy('createdDate', 'desc'))
    return onSnapshot(notesQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const data = change.doc.data();
          const note = {id: change.doc.id, ...data};
          switch (change.type) {
            case 'added': {
              this.addNote(note);
              break;
            }
            case 'modified': {
              this.updateNote(note);
              break;
            }
            case 'removed': {
              this.removeNote(note)
              break;
            }
            default:
          }
        });
        this.setNotesLoaded(true)
      },
      (error) => console.log(error));
  }

  @Action
  public async reportCustomer(data: { customer: UserAccount, message: string }) {
    const associate: AssociateAccount = profileStore.t2bUser;
    await addDoc(userReports, {
      reporter: {
        name: associate.fullName,
        uid: associate.id,
        photoUrl: associate.photoUrl?.thumbnail || null
      },
      reporting: {
        name: data.customer.fullName,
        uid: data.customer.id,
        photoUrl: data.customer.photoUrl?.thumbnail || null
      },
      message: data.message
    })
  }

  @Action
  public async isUserExist(email: string) {
    let found = await fetchSignInMethodsForEmail(auth, email.trim());
    console.log(found);
    return found;  
  }

  @Action
  public async getUserId(email: string) {

    await businessStore.loadAssociatesAsync();
    await businessStore.loadAssociates();

    let associateList = await businessStore.associates;    
    let result = await associateList.filter(o => o.email === email);
    return result[0].id;  
  }


  @Action
  public async addUser(data: {customer: UserData, userId: string}): Promise<boolean> {
    
    let uid;
    let isFirebaseSuccess = false;

    try {        
     
      
      try{
        
        // 1 create user in firebase
        if(!data.userId){
          uid = await addFirebaseUserUseCase(data.customer.email, data.customer.name, env.VUE_APP_MANUAL_PWD);       
          console.log('1 uid' + uid) 
          isFirebaseSuccess = true;          
        }else{

          // update userId
          uid = data.userId;
          await updateDoc(user(uid), 'type', 2)  
          console.log('2 update user')           
        }
      } catch (e: any) {
        console.log('Error in addFirebaseUserUseCase')
        console.log(e.message)           
        console.log(e.code);
        return false;      
      }

      if(isFirebaseSuccess){
        const rootGetters = this.context.rootGetters;
        const businessData = rootGetters.business;
        console.log('businessData.id: ' + businessData.id) 

        // 2 add in user table
        const roles: Roles = {
          associate: false,
          admin: false,
          superAdmin: false
        }

        const newAssociate: Associate = new Associate(
          data.customer.name, data.customer.email, data.customer.position, data.customer.phoneNumber,
          {id: businessData.id, name: businessData.name}, 
          2, roles.admin, roles.superAdmin, roles
        );
        newAssociate.id = uid;
        const associate = await addAssociateUseCase.invoke(newAssociate, data.customer.profilePhoto); 
        console.log('2 associate') 
        console.log(associate)

        if (!associate) {
          console.log('Error in addUser in user table') 
          return false;
        }
      }      

      // 4 add in business user table     
      data.customer.id = uid!;
      console.log('3 data.id: ' + data.customer.id) 
      console.log() 
      if(!data.customer.id){
        console.log('Error in addUser !data.id')        
      }
      const bResult = await this.addBusinessUser(data.customer);     
      console.log('4 bResult: ' + bResult)       
           
    } catch (e: any) {
      console.log('Error in add businessUser')
      console.log(e.message)
      return false;
    }
    
    console.log('5 Success addUser')
    return true;
  }

  @Action
  public async editUser(customer) {    
    if (!customer) {
      return;
    }

    try {
      const data = Object.assign({}, customer);
      const businessId = applicationStore.businessId;
      if(data.phoneNumber){
        await updateDoc(businessCustomer(businessId!, customer.id), 'phoneNumber', data.phoneNumber);
      }
      return true
    } catch (err) {
      console.log('Error in editUser') 
      console.error(err);
    }

    return false
  }

  @Action
  public async editUserPhotoUrl(customer) {    
    if (!customer) {
      return;
    }

    try {      
      const data = Object.assign({}, customer);
      const businessId = applicationStore.businessId;

      if(data.profilePhoto){
        const photoUrl = await this.getImageUrl(data.profilePhoto);
        await updateDoc(businessCustomer(businessId!, customer.id), 'photoUrl', photoUrl);
      }
      return true
    } catch (err) {
      console.log('Error in editUser') 
      console.error(err);
    }

    return false
  }

  @Action
  public async addBusinessUser(data: CustomerData): Promise<boolean> {
    
    // add in BusinessCustomer table
    const businessId = this.context.rootGetters.business.id;    
    const  photoUrl = {
      thumbnail: '', 
      normal: ''
    };
    
    if(!businessId || !data.id){
      return false;
    }

    const bsCustomer: BusinessCustomer = {
      id: data.id,
      fullName: data.name,
      email: data.email,   
      phoneNumber: data.phoneNumber,
      shared: true,
      isManual: data.isManual!,
      permissions: {
        contact: true,
        promote: true,
        viewEmail: true,
        viewDOB: true,
        viewPhone: true,
        viewAddress: false,
        viewSSN: false
      },
     photoUrl: photoUrl
    };   

    await setDoc(businessCustomer(businessId, data.id), bsCustomer); 

    return true    
  }

 
  @Action
  public async getImageUrl(imageUrl: string): Promise<PhotoUrl> {
    
    let photoUrl;

    if(imageUrl){
      const downloadUrl = imageUrl.replace('gs://','https://storage.googleapis.com/');
      const imgThumbnail = downloadUrl.replace('/profilePhoto/','/profilePhoto/thumb_');
      const imgNormal = downloadUrl.replace('/profilePhoto/','/profilePhoto/thumb_');    
      photoUrl = {thumbnail: imgThumbnail, normal: imgNormal};
    }
    
    return photoUrl;
  }
  
  @Action
  public async getSubscribersPhone(ids: []){
    let phoneNumbers: string[] = []

    try{
      const businessId = applicationStore.businessId!;
      const qSnap = await getDocs(query(businessCustomers(businessId),
        where('id', 'in', ids)));
  
      if (qSnap.size) {      
        for(let i=0; i<qSnap.size; i++){
  
          const data = qSnap.docs[i].data();
          if(data?.phoneNumber){
            let phone = data.phoneNumber.replace(/[^\d]/g, "");         
            if(phone.length >= 10){
              phone = phone + "_" + data.id;
              phoneNumbers.push(phone);
            }
          }
        }      
      }
    } catch (err) {
      console.log('Error in getSubscribersPhone') 
      console.error(err);
    }
        
    return phoneNumbers;  
  }

}

export interface CustomerData {
  id?: string
  name: string
  password?: string
  email: string  
  phoneNumber: string
  position?: string
  role?: number
  autoCreateContact?: boolean
  profilePhoto?: any
  sendInvite?: boolean
  mode?: string
  ssn?: string
  business?: { id: string, name: string }
  isManual?: boolean
}

export interface PhotoUrl {
  thumbnail: string | null
  normal: string | null
}

export interface BusinessCustomer {  
  fullName: string
  email: string  
  phoneNumber: string
  permissions: DeafultPermissions
  shared: boolean
  isManual: boolean
  photoUrl: PhotoUrl
  id?: string
}

export interface  DeafultPermissions {
  contact: boolean,
  promote: boolean,
  viewEmail: boolean,
  viewDOB: boolean,
  viewPhone: boolean,
  viewAddress: boolean,
  viewSSN: boolean
}


export interface UserData {
  id?: string
  name: string
  password?: string
  email: string
  position: string
  phoneNumber: string
  role: number
  autoCreateContact?: boolean
  profilePhoto?: any
  sendInvite: boolean
  mode?: string
  ssn?: string
  business?: { id: string, name: string }
  isManual: boolean
}

export const customersStore = getModule(CustomersStore);
